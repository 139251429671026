var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-card', [_c('h3', [_vm._v("Info Barang")]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "8"
    }
  }, [_c('table', {
    staticClass: "table responsive table-responsive w-100 table-borderless"
  }, [_c('tr', [_c('td', [_c('strong', [_vm._v("Nama Barang")])]), _c('td', [_vm._v(_vm._s(_vm.barang.nama))]), _c('td', [_c('strong', [_vm._v("Kategori")])]), _c('td', [_vm._v(_vm._s(_vm.barang.kategori === 1 ? _vm.barang.kategori.kategori : 'kosong'))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Kode Barang")])]), _c('td', [_vm._v(_vm._s(_vm.barang.kode))]), _c('td', [_c('strong', [_vm._v("Satuan Barang")])]), _c('td', [_vm._v(_vm._s(_vm.barang.satuan ? _vm.barang.satuan.satuan : 'kosong'))])])])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-card', [_c('h5', [_vm._v("Stok Barang")]), _c('b-table', {
    attrs: {
      "fields": _vm.stockFields,
      "responsive": "",
      "items": _vm.barang.stocks,
      "bordered": "",
      "striped": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-radio', {
          attrs: {
            "value": item,
            "name": "barang"
          },
          model: {
            value: _vm.selectedStock,
            callback: function ($$v) {
              _vm.selectedStock = $$v;
            },
            expression: "selectedStock"
          }
        })];
      }
    }, {
      key: "cell(gudang)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.gudang ? item.gudang.nama_gudang : '-') + " ")];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : '-') + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : '-') + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : '-') + " ")];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-card', [_c('form', [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "gudang",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Gudang")]), _c('v-select', {
          attrs: {
            "options": _vm.gudangs,
            "required": !_vm.currentStock.gudang_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.currentStock.gudang_id,
            callback: function ($$v) {
              _vm.$set(_vm.currentStock, "gudang_id", $$v);
            },
            expression: "currentStock.gudang_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Blok")]), _c('v-select', {
          attrs: {
            "options": _vm.bloks,
            "required": !_vm.currentStock.blok_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.currentStock.blok_id,
            callback: function ($$v) {
              _vm.$set(_vm.currentStock, "blok_id", $$v);
            },
            expression: "currentStock.blok_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Rak (optional)")]), _c('v-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.raks,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.currentStock.rak_id,
      callback: function ($$v) {
        _vm.$set(_vm.currentStock, "rak_id", $$v);
      },
      expression: "currentStock.rak_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Laci (optional)")]), _c('v-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.lacis,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.currentStock.laci_id,
      callback: function ($$v) {
        _vm.$set(_vm.currentStock, "laci_id", $$v);
      },
      expression: "currentStock.laci_id"
    }
  })], 1), _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "stok",
      "rules": "required|positive"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var errors = _ref8.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("stok")]), _c('b-form-input', {
          staticClass: "mb-2",
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.currentStock.stok,
            callback: function ($$v) {
              _vm.$set(_vm.currentStock, "stok", $$v);
            },
            expression: "currentStock.stok"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)], 1)])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }